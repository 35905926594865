<template>
	<el-container id="container" style="min-height: 2199px;" direction="vertical">
		<div class="block">
			<img src="../../../assets/image/aboutbg.png" alt="">
			<div class="text1">
				公司简介
			</div>
			
			<div class="text2">
				湖南壹本账信息科技有限公司，总部坐落于湖南省湘潭市天易示范区，是一家立足业财
				<br>融合数据中台、致力于为中国千万中小企业提供数字化经营解决方案的高新技术企业。
			</div>
			
			<div class="text2" style="top: 350px;">
				公司创新应用“三位一体”管理模式，通过业务流程再造和互联网、云计算、人工智能
				<br>（AI）等数字技术支撑“协同发力”，全面整合企业内部分散在各个孤岛上的数据，实
				<br>现多点聚合、业财融合，为老板的经营决策、精细化运营提供数据支撑。
			</div>
		</div>
		
		<div class="block2">
			<div style="width: 1500px; height: 1000px !important;margin-left: 210px;">
				<div class="text3">公司荣誉及资质</div>
				<el-carousel height="900px" loop arrow="always" style="margin: 100px 0 0 0;">
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book1.png" alt="" style="width: 815px; height: 600px; padding-top: 20px;">
						</div>
						<div class="text4">发明专利证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book2.png" alt="" style="width: 473px; height: 639px; padding-top: 20px;">
						</div>
						<div class="text4" style="left: 595px;">国家质量管理体系认证</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book3.png" alt="" style="width: 851px; height: 636px; padding-top: 20px;">
						</div>
						<div class="text4">软件企业证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book4.png" alt="" style="width: 851px; height: 636px; padding-top: 20px;">
						</div>
						<div class="text4">软件产品证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book5.png" alt="" style="width: 902px; height: 598px; padding-top: 20px;">
						</div>
						<div class="text4">软件著作证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book6.png" alt="" style="width: 902px; height: 598px; padding-top: 20px;">
						</div>
						<div class="text4">软件著作证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book7.png" alt="" style="width: 936px; height: 633px; padding-top: 20px;">
						</div>
						<div class="text4">商标注册证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book8.png" alt="" style="width: 936px; height: 633px; padding-top: 20px;">
						</div>
						<div class="text4">商标注册证书</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="imgbox">
							<img src="../../../assets/image/book9.png" alt="" style="width: 851px; height: 636px; padding-top: 20px;">
						</div>
						<div class="text4" style="left: 625px;">高新技术企业证书</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	
		<div class="block3">
			<div class="arrow1">
				<div class="yuan"></div>
			</div>
			
			<div class="arrow2">
				<div class="yuan"></div>
			</div>
			
			<div class="text5">
				创始人
			</div>
			
			<div class="text6">
				壹本账创始人孙同献老师，注册会计师、税务师，先后在外资企业、民营企业和集团公司从事
				<br>财务管理工作20年，担任财务总监、审计总监、信息化总监。
			</div>
			
			<div class="text6" style="top: 312px;">
				立足“懂财务＋懂管理＋懂信息化”三大独特优势，孙老师从20多年的管理实践中，总结出<b>7大</b>
				<br><b>管理维度、373业务模型</b>等一系列原创性管理理论，并开创性地自主研发了壹本账管理系统。
			</div>
			
			<div class="text6" style="top: 394px;">
				2017年6月至今，孙老师深耕财税行业，以促进财税行业转型升级为己任，全方位赋能财税机构
				<br>开拓管理账（财务外包），赢得众多机构的口碑与信赖。
			</div>
			
			<img src="../../../assets/image/person6.png" alt="" style="position: absolute; top: 0px; left: 1362px; z-index: 1000;">
			
			<div class="text7">
				孙同献
			</div>
			
			<div class="text6" style="left: 1170px; top: 294px; z-index: 1000;">
				壹本账创始人
			</div>
			
			<div class="text8" style="left: 1170px; top: 334px; z-index: 1000;">
				<li>资深信息化专家</li><li>集团公司财务总监</li><li>注册会计师、税务师</li>
			</div>
		</div>
	</el-container>
</template>

<script>
  export default {
    name: "about"
  }
</script>

<style lang="less" scoped>
	.el-container {
		width: 1920px;
		
		.block {
			width: 1920px;
			height: 620px;
			position: absolute;
			
			.text1 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 72px;
				position: absolute;
				top: 163px;
				left: 792px;
			}
			
			.text2 {
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-size: 24px;
				font-weight: normal;
				color: #000000;
				line-height: 40px;
				position: absolute;
				top: 244px;
				left: 792px;
			}
		}
		
		.block2 {
			width: 1920px;
			height: 1000px;
			background: linear-gradient(0deg, #E3C8BA 0%, #FEEDE5 100%);
			position: absolute;
			top: 720px;
			
			.imgbox {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1500px; 
				height: 739px;
			}
			
			::v-deep .el-carousel__button {
				width: 18px;
				height: 18px;
				background-color: #FFFFFF;
				border-radius: 50%;
				margin-right: 21px;
				border: 1px solid #000000;
			}
			
			::v-deep .el-carousel__indicator.is-active button {
				background-color: #65310C;
			}
			
			::v-deep .el-carousel__indicator--horizontal {
				margin-bottom: 60px;
			}
			
			.text3 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 48px;
				position: absolute;
				left: 796px;
			}
			
			.text4 {
				font-size: 32px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 32px;
				position: absolute;
				left: 656px;
				top: 709px;
			}
		}
		
		.block3 {
			width: 1920px;
			height: 580px;
			background: #F6F7F9;
			position: absolute;
			top: 1720px;
			
			.arrow1 {
				width: 252px;
				height: 290px;
				opacity: 0.74;
				position: absolute;
				top: 288px;
				z-index: 100;
				overflow: hidden; 
				text-overflow:ellipsis;
				white-space: nowrap;
				
				.yuan {
					width: 500px;
					height: 500px;
					border-radius: 250px;
					background: linear-gradient(0deg, #FFFFFF 0%, #E6CCBF 100%);
					z-index: -1;
					margin-left: -250px;
				}
			}
			
			.arrow2 {
				width: 607px;
				height: 493px;
				opacity: 0.74;
				position: absolute;
				top: 87px;
				left: 1247px;
				z-index: 100;
				overflow: hidden; 
				text-overflow:ellipsis;
				white-space: nowrap;
				
				.yuan {
					width: 606px;
					height: 606px;
					border-radius: 606px;
					background: linear-gradient(0deg, #FFFFFF 0%, #E6CCBF 100%);
					z-index: -1;
					margin-bottom: -167px;
				}
			}
		
			.text5 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 72px;
				position: absolute;
				top: 137px;
				left: 210px;
			}
			
			.text6 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 30px;
				position: absolute;
				top: 231px;
				left: 210px;
				z-index: 1000;
			}
			
			.text7 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 30px;
				position: absolute;
				top: 259px;
				left: 1170px;
			}
			
			.text8 {
				font-size: 18px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 24px;
				position: absolute;
			}
		}
	}
</style>